import React from 'react'
import { graphql } from 'gatsby'
import { SSRProvider } from 'react-aria'
import Layout from '../components/Layout'
import { HeaderProps } from '../components/Header'
import Hero, { HeroProps } from '../components/Hero'
import ContentSection, {
  ContentSectionProps
} from '../components/ContentSection'
import MenuFile, { MenuFileProps } from '../components/MenuFile'
import LocationsSection from '../components/LocationsSection'
import { RestaurantLocationProps } from '../components/RestaurantLocation'
import { FooterProps } from '../components/Footer'

interface HomePageProps {
  data: {
    markdownRemark: {
      frontmatter: HomeTemplateProps
    }
  }
}

interface HomeTemplateProps {
  seo: {
    title: string
    description: string | undefined
    keywords: string | undefined
  }
  header: HeaderProps | undefined
  hero: HeroProps | undefined
  content: Array<ContentSectionProps> | undefined
  menuFiles: Array<MenuFileProps> | undefined
  locations: Array<RestaurantLocationProps> | undefined
  footer: FooterProps | undefined
}

export function HomeTemplate({
  hero,
  content,
  menuFiles,
  locations
}: HomeTemplateProps) {
  return (
    <>
      <Hero {...hero} locations={locations} />
      <div>
        {content?.map((section) => (
          <ContentSection key={section.markdown} {...section} />
        ))}
      </div>
      <div>
        {menuFiles?.map((menuFile) => {
          if (menuFile.pdf) {
            return <MenuFile key={menuFile.pdf.id} {...menuFile} />
          }
        })}
      </div>
      <LocationsSection locations={locations} />
    </>
  )
}

export function Head(props: HomePageProps) {
  const { seo } = props.data.markdownRemark.frontmatter

  return (
    <>
      <title>{seo.title}</title>
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}
    </>
  )
}

export default function HomePage(props: HomePageProps) {
  const { frontmatter } = props.data.markdownRemark

  return (
    <SSRProvider>
      <Layout {...frontmatter}>
        <HomeTemplate {...frontmatter} />
      </Layout>
    </SSRProvider>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "index" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        header {
          logo {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
        hero {
          title
          subtitle
          background {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        content {
          header
          markdown
          images {
            image {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
        menuFiles {
          label
          pdf {
            id
            publicURL
          }
        }
        locations {
          name
          address
          hours
          pickupUrl
          deliveryUrl
        }
        footer {
          instagram
          facebook
          twitter
          pinterest
          tripadvisor
          yelp
        }
      }
    }
  }
`
