import React, { useRef } from 'react'
import { classnames } from '../utils'

interface BaseButtonProps {
  component?: React.ElementType<any>
  variant?: 'default' | 'outline'
  size?: 'small' | 'large'
}

type ButtonAsButtonProps = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

type ButtonAsAnchorProps = BaseButtonProps & {
  component: 'a'
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

type ButtonProps = ButtonAsButtonProps | ButtonAsAnchorProps

export default function Button({
  children,
  className,
  component: Component = 'button',
  size,
  variant,
  ...props
}: ButtonProps) {
  const ref = useRef(null)
  const buttonProps = Component === 'button' ? { type: 'button' } : {}

  let variantClass = 'bg-primary text-white'
  if (variant === 'outline') {
    variantClass =
      'text-primary border border-primary hover:text-white hover:bg-primary'
  }
  let sizeClass = 'px-8 py-3'
  if (size === 'small') {
    sizeClass = 'px-4 py-2 text-sm'
  } else if (size === 'large') {
    sizeClass = 'px-16 py-5 text-xl'
  }

  return (
    <Component
      ref={ref}
      className={classnames(
        'inline-block rounded-full focus:outline-none transition font-bold text-center',
        variantClass,
        sizeClass,
        className
      )}
      {...buttonProps}
      {...props}
    >
      {children}
    </Component>
  )
}
