import React from 'react'
import { marked } from 'marked'
import { classnames } from '../utils'

export default function Markdown({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: string | null | undefined
}) {
  return (
    <div
      className={classnames('markdown', className)}
      dangerouslySetInnerHTML={{
        __html: marked.parse(children || '')
      }}
      {...props}
    />
  )
}
