import React from 'react'
import Header, { HeaderProps } from './Header'
import Footer, { FooterProps } from './Footer'

export default function Layout({
  children,
  header,
  footer
}: {
  children: JSX.Element
  header: HeaderProps | undefined
  footer: FooterProps | undefined
}) {
  return (
    <>
      <Header {...header} />
      {children}
      <Footer {...footer} />
    </>
  )
}
