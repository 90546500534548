import React from 'react'
import Button from './Button'
import { RestaurantLocationProps } from './RestaurantLocation'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

export default function Cta({
  locations
}: {
  locations: Array<RestaurantLocationProps>
}) {
  // Scroll to bottom if more than one location
  const shouldScroll = locations.length > 1
  const href = shouldScroll ? '#locations' : locations[0].pickupUrl
  const LinkComponent = shouldScroll ? 'a' : OutboundLink

  if (!href) {
    return <></>
  }

  return (
    <Button component={LinkComponent} href={href} size="large">
      Order Pickup
    </Button>
  )
}
