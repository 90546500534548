import React from 'react'
import { classnames } from '../utils'

export default function Container({
  children,
  ...props
}: { children: React.ReactNode } & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classnames(
        'mx-auto px-8 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl',
        props.className
      )}
    >
      {children}
    </div>
  )
}
