import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Markdown from './Markdown'
import Button from './Button'

export interface RestaurantLocationProps {
  name: string | undefined
  address: string | undefined
  pickupUrl: string | undefined
  deliveryUrl: string | undefined
  hours: string | undefined
}

export default function Location({
  name,
  address,
  pickupUrl,
  deliveryUrl,
  hours
}: RestaurantLocationProps) {
  return (
    <div className="border-b border-[#bdbdbd] only:border-0 pb-8 only:pb-0 mb-8 last:mb-0">
      <div className="flex flex-wrap justify-between -mt-4">
        <div className="mt-4">
          <h4 className="font-medium text-xl">{name}</h4>
          {address && (
            <OutboundLink
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/place/${address
                .split(' ')
                .join('+')}`}
              className="text-lg underline text-[#4F4F4F]"
            >
              {address}
            </OutboundLink>
          )}
          {hours && <Markdown className="mt-4">{hours}</Markdown>}
        </div>
        <div className="mt-4 -mb-4 flex flex-wrap items-start gap-2">
          {pickupUrl && (
            <OutlineOutboundLink href={pickupUrl}>
              Order Pickup
            </OutlineOutboundLink>
          )}
          {deliveryUrl && (
            <OutlineOutboundLink href={deliveryUrl}>
              Order Delivery
            </OutlineOutboundLink>
          )}
        </div>
      </div>
    </div>
  )
}

function OutlineOutboundLink({
  href,
  children
}: {
  href: string
  children: string
}) {
  return (
    <Button
      component={OutboundLink}
      href={href}
      variant="outline"
      className="block mb-2 font-medium"
    >
      {children}
    </Button>
  )
}
