import React from 'react'
import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  ImageDataLike
} from 'gatsby-plugin-image'

interface PreviewImage {
  field: any
  fileObj: any
  path: string
  url: string
}

export type ImageData = ImageDataLike | PreviewImage

export function isGatsbyImage(image: unknown): image is ImageDataLike {
  return (
    typeof image === 'object' &&
    Object.prototype.hasOwnProperty.call(image, 'childImageSharp')
  )
}

export function isPreviewImage(image: unknown): image is PreviewImage {
  return (
    typeof image === 'object' &&
    Object.prototype.hasOwnProperty.call(image, 'url')
  )
}

export interface ImageProps extends Omit<GatsbyImageProps, 'image'> {
  image: ImageDataLike
}

export default function Image({ image, ...props }: ImageProps) {
  const gatsbyImage = getImage(image)

  if (gatsbyImage) {
    return <GatsbyImage image={gatsbyImage} {...props} />
  }

  return <></>
}
