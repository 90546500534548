import React from 'react'
import Image, { ImageData, isGatsbyImage, isPreviewImage } from './Image'

export interface HeaderProps {
  logo?: ImageData
}

export default function Header({ logo }: HeaderProps) {
  return (
    <header className="pt-4 pb-4 flex justify-center">
      {isGatsbyImage(logo) && (
        <Image image={logo} alt="logo" className="h-14" objectFit="contain" />
      )}
      {isPreviewImage(logo) && (
        <img src={logo.url} alt="logo" className="h-14 object-contain" />
      )}
    </header>
  )
}
