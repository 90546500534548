import React from 'react'
import Button from './Button'

export interface MenuFileProps {
  label: string
  pdf: { id: string; publicURL: string } | undefined
}

export default function MenuFile({ label, pdf }: MenuFileProps) {
  if (!pdf) {
    return null
  }

  return (
    <div className="mt-10 last:mb-20 px-8 text-center">
      <Button component="a" key={pdf.id} href={pdf.publicURL} target="_blank">
        {label}
      </Button>
    </div>
  )
}
