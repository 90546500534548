import React from 'react'
import Container from './Container'
import RestaurantLocation, {
  RestaurantLocationProps
} from './RestaurantLocation'

export default function LocationsSection({
  locations
}: {
  locations: Array<RestaurantLocationProps> | undefined
}) {
  return (
    <section id="locations" className="py-32 bg-[#F7F7F7]">
      <Container>
        {locations?.map((location) => (
          <RestaurantLocation key={location.name} {...location} />
        ))}
      </Container>
    </section>
  )
}
