import React from 'react'
import Container from './Container'
import Markdown from './Markdown'
import Image, { ImageData, isGatsbyImage, isPreviewImage } from './Image'

export interface ContentSectionProps {
  header: string | null
  markdown: string | null | undefined
  images: Array<{ image: ImageData }> | undefined
}

export default function ContentSection({
  header,
  markdown,
  images
}: ContentSectionProps) {
  return (
    <section className="mt-24 last:mb-24">
      <Container>
        {header && <h3 className="text-center text-2xl mb-6">{header}</h3>}
        <Markdown>{markdown}</Markdown>
      </Container>
      {Array.isArray(images) && images.length > 0 && (
        <div className="mt-20 mx-auto lg:max-w-screen-xl xl:max-w-screen-2xl">
          <div className="flex flex-wrap justify-center px-4 -mt-4">
            {images.map(({ image }, idx) => {
              if (isGatsbyImage(image)) {
                return (
                  <Image
                    key={idx}
                    image={image}
                    alt=""
                    className="md:w-1/2 px-4 mt-4"
                    objectFit="contain"
                  />
                )
              } else if (isPreviewImage(image)) {
                return (
                  <img
                    key={idx}
                    src={image.url}
                    className="md:w-1/2 px-4 mt-4 object-contain"
                  />
                )
              }
            })}
          </div>
        </div>
      )}
    </section>
  )
}
