import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Facebook from './SocialIcons/Facebook'
import Instagram from './SocialIcons/Instagram'
import Twitter from './SocialIcons/Twitter'
import Pinterest from './SocialIcons/Pinterest'
import Tripadvisor from './SocialIcons/Tripadvisor'
import Yelp from './SocialIcons/Yelp'

export interface FooterProps {
  instagram?: string
  facebook?: string
  twitter?: string
  pinterest?: string
  tripadvisor?: string
  yelp?: string
}

export default function Footer({
  facebook,
  instagram,
  twitter,
  pinterest,
  tripadvisor,
  yelp
}: FooterProps) {
  return (
    <footer className="bg-[#383838] py-10">
      <div className="flex justify-center items-center mb-8">
        {facebook && (
          <SocialLink href={facebook}>
            <Facebook />
          </SocialLink>
        )}
        {instagram && (
          <SocialLink href={instagram}>
            <Instagram />
          </SocialLink>
        )}
        {twitter && (
          <SocialLink href={twitter}>
            <Twitter />
          </SocialLink>
        )}
        {pinterest && (
          <SocialLink href={pinterest}>
            <Pinterest />
          </SocialLink>
        )}
        {tripadvisor && (
          <SocialLink href={tripadvisor}>
            <Tripadvisor />
          </SocialLink>
        )}
        {yelp && (
          <SocialLink href={yelp}>
            <Yelp />
          </SocialLink>
        )}
      </div>
      <div className="flex justify-center">
        <a href="https://belugatech.com">
          <div className="flex items-center">
            <span className="text-white mr-2">
              Powered by <span className="underline">Beluga</span>
            </span>
            <svg
              width="21"
              height="10"
              viewBox="0 0 21 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5292 2.22617C10.6503 2.22241 10.7672 2.18348 10.8636 2.11475C10.9601 2.04602 11.0314 1.95089 11.0677 1.84252C11.3754 1.04348 11.9662 0.374246 12.8569 0.136265C14.3846 -0.271909 15.78 0.348284 17.28 0.544438C18.0723 0.646842 18.8508 0.478092 19.62 0.313669C19.6703 0.302093 19.722 0.296281 19.7738 0.29636C19.8689 0.29579 19.9631 0.312819 20.051 0.346468C20.139 0.380117 20.219 0.429724 20.2865 0.492441C20.354 0.555159 20.4076 0.629752 20.4442 0.711938C20.4808 0.794124 20.4998 0.882285 20.5 0.971361C20.5 0.971361 20.5 4.3305 15.0354 6.49108C13.7308 7.01175 12.5308 7.85838 11.4938 8.75694C11.2229 8.99067 10.8682 9.12041 10.5 9.12041C10.1318 9.12041 9.77708 8.99067 9.50615 8.75694C8.46923 7.85838 7.27692 7.01175 5.96923 6.49108C0.5 4.3305 0.5 0.971361 0.5 0.971361C0.500406 0.792457 0.576394 0.620989 0.711332 0.494485C0.84627 0.36798 1.02917 0.296742 1.22 0.29636C1.27184 0.296281 1.3235 0.302093 1.37385 0.313669C2.14308 0.478092 2.92154 0.646842 3.71385 0.544438C5.22 0.348284 6.60923 -0.271909 8.13692 0.136265C9.02769 0.374246 9.61846 1.04348 9.92615 1.84252C9.96244 1.95089 10.0337 2.04602 10.1302 2.11475C10.2267 2.18348 10.3435 2.22241 10.4646 2.22617"
                fill="white"
              />
            </svg>
          </div>
        </a>
      </div>
    </footer>
  )
}

function SocialLink({
  children,
  href
}: {
  children: JSX.Element
  href: string
}) {
  return (
    <OutboundLink
      href={href}
      className="border border-white rounded-full flex items-center justify-center mr-2 last:mr-0 w-11 h-11"
    >
      {children}
    </OutboundLink>
  )
}
