import React from 'react'
import Cta from './Cta'
import { RestaurantLocationProps } from './RestaurantLocation'
import Image, { ImageData, isGatsbyImage, isPreviewImage } from './Image'
import HeroBelugaLink from './HeroBelugaLink'
import GiftCertificatesButton from './GiftCertificatesButton'
import DeliveryButton from './DeliveryButton'
import CustomCtaContent from './CustomCtaContent'

export interface HeroProps {
  title?: string | undefined
  subtitle?: string | undefined
  background?: ImageData
}

export default function Hero({
  background,
  title,
  subtitle,
  locations
}: HeroProps & { locations: Array<RestaurantLocationProps> | undefined }) {
  return (
    <section className="px-0 md:px-8">
      <div className="py-24 sm:py-32 md:py-52 relative">
        {isGatsbyImage(background) && (
          <Image
            image={background}
            alt=""
            className="!absolute top-0 right-0 bottom-0 left-0"
          />
        )}
        {isPreviewImage(background) && (
          <div
            className="absolute top-0 right-0 bottom-0 left-0 bg-cover bg-center"
            style={{ backgroundImage: `url('${background}')` }}
          />
        )}
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black/[.3]" />
        <div className="relative text-center mx-auto px-8 lg:max-w-screen-sm xl:max-w-screen-md">
          {Array.isArray(locations) && locations.length > 0 && (
            <GiftCertificatesButton locations={locations} />
          )}
          <h1 className="text-6xl text-[#F7F7F7] font-bold mb-6 font-display leading-snug">
            {title}
          </h1>
          <h2 className="text-2xl text-[#F7F7F7] mb-20">{subtitle}</h2>
          <div className="flex flex-wrap justify-center gap-4">
            {Array.isArray(locations) && locations.length > 0 && (
              <>
                <Cta locations={locations} />
                <DeliveryButton locations={locations} />
              </>
            )}
          </div>
          <CustomCtaContent />
          <HeroBelugaLink />
        </div>
      </div>
    </section>
  )
}
